<template>
  <div>
    <pap-register />
  </div>
</template>
<script>
import PapRegister from '@/components/Auth/Register'
export default {
  components: {
    PapRegister,
  },
}
</script>
