<template>
  <a-modal :visible="previewVisibleKtp" :footer="null" @cancel="handleCancelKtp" centered>
    <img
      alt="example"
      style="width: 100%;margin-top: 30px; object-fit: cover;"
      :src="previewImageKtp"
    />
  </a-modal>
  <a-modal :visible="previewVisibleSelfie" :footer="null" @cancel="handleCancelSelfie" centered>
    <img
      alt="example"
      style="width: 100%;margin-top: 30px; object-fit: cover;"
      :src="previewImageSelfie"
    />
  </a-modal>
  <div class="bg-white d-flex justify-content-center align-items-center pt-5">
    <div :class="$style.container">
      <div class="d-flex align-items-center mb-5">
        <router-link to="/auth/login"
          ><svg
            width="32"
            height="16"
            viewBox="0 0 32 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM32 7H1V9H32V7Z"
              fill="#800152"
            />
          </svg>
        </router-link>
        <h1 class="w-100 text-center" :class="$style['custom-title-register']">Daftar</h1>
      </div>
      <!-- <div class="text-dark font-size-32 mb-3">Create your account</div>
      <div class="mb-4">
        <p>
          And start spending more time on your projects and less time managing your infrastructure.
        </p>
      </div> -->
      <a-form
        :model="registerForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item
          name="fullname"
          label="Nama lengkap sesuai KTP"
          :class="$style['custom-form-item']"
        >
          <a-input
            v-model:value="registerForm.fullname"
            placeholder="Masukkan nama lengkap sesuai KTP"
            :class="$style['custom-input-register']"
          />
        </a-form-item>
        <a-form-item
          name="dateOfBirth"
          label="Tanggal lahir sesuai KTP"
          :class="$style['custom-form-item']"
        >
          <a-date-picker
            v-model:value="registerForm.dateOfBirth"
            placeholder="YYYY-MM-DD"
            :class="$style['custom-input-date-register']"
            class="w-100"
          ></a-date-picker>
        </a-form-item>
        <a-form-item name="idNumber" label="No KTP" :class="$style['custom-form-item']">
          <a-input
            v-model:value="registerForm.idNumber"
            placeholder="Masukkan No KTP"
            :class="$style['custom-input-register']"
          ></a-input>
        </a-form-item>

        <div class="row">
          <div class="col-12 col-sm-6">
            <a-form-item
              name="idPicture"
              label="Unggah foto KTP"
              :class="$style['custom-form-item-upload']"
            >
              <div :class="$style['custom-container-upload-file']" @click="handleClickKtpModal">
                <div
                  class="d-flex justify-content-between align-items-center w-100 px-5"
                  v-if="!registerForm.idPicture"
                >
                  <div>
                    <svg
                      width="62"
                      height="62"
                      viewBox="0 0 62 62"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M51.7715 15.0232H43.675L41.7462 9.61757C41.6129 9.24768 41.3687 8.92795 41.0469 8.70206C40.7251 8.47616 40.3414 8.35512 39.9483 8.35547H21.6836C20.8799 8.35547 20.1595 8.8615 19.8916 9.61757L17.9568 15.0232H9.8603C7.22894 15.0232 5.09766 17.1544 5.09766 19.7858V46.9329C5.09766 49.5642 7.22894 51.6955 9.8603 51.6955H51.7715C54.4029 51.6955 56.5342 49.5642 56.5342 46.9329V19.7858C56.5342 17.1544 54.4029 15.0232 51.7715 15.0232ZM52.2478 46.9329C52.2478 47.1948 52.0335 47.4091 51.7715 47.4091H9.8603C9.59835 47.4091 9.38403 47.1948 9.38403 46.9329V19.7858C9.38403 19.5239 9.59835 19.3095 9.8603 19.3095H20.9751L21.9931 16.4639L23.3564 12.6418H38.2694L39.6328 16.4639L40.6508 19.3095H51.7715C52.0335 19.3095 52.2478 19.5239 52.2478 19.7858V46.9329ZM30.8159 23.1197C25.5532 23.1197 21.2906 27.3822 21.2906 32.6449C21.2906 37.9077 25.5532 42.1702 30.8159 42.1702C36.0786 42.1702 40.3412 37.9077 40.3412 32.6449C40.3412 27.3822 36.0786 23.1197 30.8159 23.1197ZM30.8159 38.3601C27.6607 38.3601 25.1007 35.8002 25.1007 32.6449C25.1007 29.4897 27.6607 26.9298 30.8159 26.9298C33.9712 26.9298 36.5311 29.4897 36.5311 32.6449C36.5311 35.8002 33.9712 38.3601 30.8159 38.3601Z"
                        fill="#595C97"
                      />
                    </svg>
                  </div>
                  <div>atau</div>
                  <div>
                    <svg
                      width="62"
                      height="62"
                      viewBox="0 0 62 62"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.81641 40.2653L18.9207 27.161C19.4514 26.6303 20.0814 26.2094 20.7748 25.9221C21.4682 25.6349 22.2114 25.4871 22.9619 25.4871C23.7124 25.4871 24.4556 25.6349 25.149 25.9221C25.8424 26.2094 26.4725 26.6303 27.0032 27.161L40.1074 40.2653M36.2973 36.4552L39.8763 32.8762C40.407 32.3455 41.037 31.9245 41.7304 31.6373C42.4238 31.3501 43.167 31.2023 43.9175 31.2023C44.6681 31.2023 45.4112 31.3501 46.1046 31.6373C46.798 31.9245 47.4281 32.3455 47.9588 32.8762L55.3479 40.2653M9.62652 49.7906H51.5378C52.5483 49.7906 53.5174 49.3891 54.2319 48.6746C54.9464 47.9601 55.3479 46.991 55.3479 45.9805V15.4996C55.3479 14.4891 54.9464 13.5199 54.2319 12.8054C53.5174 12.0909 52.5483 11.6895 51.5378 11.6895H9.62652C8.61601 11.6895 7.6469 12.0909 6.93236 12.8054C6.21783 13.5199 5.81641 14.4891 5.81641 15.4996V45.9805C5.81641 46.991 6.21783 47.9601 6.93236 48.6746C7.6469 49.3891 8.61601 49.7906 9.62652 49.7906ZM36.2973 21.2147H36.3176V21.2351H36.2973V21.2147ZM37.2498 21.2147C37.2498 21.4674 37.1495 21.7096 36.9708 21.8883C36.7922 22.0669 36.5499 22.1673 36.2973 22.1673C36.0447 22.1673 35.8024 22.0669 35.6238 21.8883C35.4451 21.7096 35.3448 21.4674 35.3448 21.2147C35.3448 20.9621 35.4451 20.7198 35.6238 20.5412C35.8024 20.3626 36.0447 20.2622 36.2973 20.2622C36.5499 20.2622 36.7922 20.3626 36.9708 20.5412C37.1495 20.7198 37.2498 20.9621 37.2498 21.2147Z"
                        stroke="#595C97"
                        stroke-width="3.81011"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <img
                  :src="`${baseURL}${registerForm.idPicture}`"
                  class="w-100 h-100"
                  style="object-fit: cover;"
                  alt=""
                  v-else
                />
              </div>
            </a-form-item>
          </div>
          <div class="col-12 col-sm-6">
            <a-form-item>
              <a-form-item
                name="selfiePicture"
                label="Unggah selfie"
                :class="$style['custom-form-item-upload']"
              >
                <div
                  :class="$style['custom-container-upload-file']"
                  @click="handleClickSelfieModal"
                >
                  <div
                    class="d-flex justify-content-between align-items-center w-100 px-5"
                    v-if="!registerForm.selfiePicture"
                  >
                    <div>
                      <svg
                        width="62"
                        height="62"
                        viewBox="0 0 62 62"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M51.7715 15.0232H43.675L41.7462 9.61757C41.6129 9.24768 41.3687 8.92795 41.0469 8.70206C40.7251 8.47616 40.3414 8.35512 39.9483 8.35547H21.6836C20.8799 8.35547 20.1595 8.8615 19.8916 9.61757L17.9568 15.0232H9.8603C7.22894 15.0232 5.09766 17.1544 5.09766 19.7858V46.9329C5.09766 49.5642 7.22894 51.6955 9.8603 51.6955H51.7715C54.4029 51.6955 56.5342 49.5642 56.5342 46.9329V19.7858C56.5342 17.1544 54.4029 15.0232 51.7715 15.0232ZM52.2478 46.9329C52.2478 47.1948 52.0335 47.4091 51.7715 47.4091H9.8603C9.59835 47.4091 9.38403 47.1948 9.38403 46.9329V19.7858C9.38403 19.5239 9.59835 19.3095 9.8603 19.3095H20.9751L21.9931 16.4639L23.3564 12.6418H38.2694L39.6328 16.4639L40.6508 19.3095H51.7715C52.0335 19.3095 52.2478 19.5239 52.2478 19.7858V46.9329ZM30.8159 23.1197C25.5532 23.1197 21.2906 27.3822 21.2906 32.6449C21.2906 37.9077 25.5532 42.1702 30.8159 42.1702C36.0786 42.1702 40.3412 37.9077 40.3412 32.6449C40.3412 27.3822 36.0786 23.1197 30.8159 23.1197ZM30.8159 38.3601C27.6607 38.3601 25.1007 35.8002 25.1007 32.6449C25.1007 29.4897 27.6607 26.9298 30.8159 26.9298C33.9712 26.9298 36.5311 29.4897 36.5311 32.6449C36.5311 35.8002 33.9712 38.3601 30.8159 38.3601Z"
                          fill="#595C97"
                        />
                      </svg>
                    </div>
                    <div>atau</div>
                    <div>
                      <svg
                        width="62"
                        height="62"
                        viewBox="0 0 62 62"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.81641 40.2653L18.9207 27.161C19.4514 26.6303 20.0814 26.2094 20.7748 25.9221C21.4682 25.6349 22.2114 25.4871 22.9619 25.4871C23.7124 25.4871 24.4556 25.6349 25.149 25.9221C25.8424 26.2094 26.4725 26.6303 27.0032 27.161L40.1074 40.2653M36.2973 36.4552L39.8763 32.8762C40.407 32.3455 41.037 31.9245 41.7304 31.6373C42.4238 31.3501 43.167 31.2023 43.9175 31.2023C44.6681 31.2023 45.4112 31.3501 46.1046 31.6373C46.798 31.9245 47.4281 32.3455 47.9588 32.8762L55.3479 40.2653M9.62652 49.7906H51.5378C52.5483 49.7906 53.5174 49.3891 54.2319 48.6746C54.9464 47.9601 55.3479 46.991 55.3479 45.9805V15.4996C55.3479 14.4891 54.9464 13.5199 54.2319 12.8054C53.5174 12.0909 52.5483 11.6895 51.5378 11.6895H9.62652C8.61601 11.6895 7.6469 12.0909 6.93236 12.8054C6.21783 13.5199 5.81641 14.4891 5.81641 15.4996V45.9805C5.81641 46.991 6.21783 47.9601 6.93236 48.6746C7.6469 49.3891 8.61601 49.7906 9.62652 49.7906ZM36.2973 21.2147H36.3176V21.2351H36.2973V21.2147ZM37.2498 21.2147C37.2498 21.4674 37.1495 21.7096 36.9708 21.8883C36.7922 22.0669 36.5499 22.1673 36.2973 22.1673C36.0447 22.1673 35.8024 22.0669 35.6238 21.8883C35.4451 21.7096 35.3448 21.4674 35.3448 21.2147C35.3448 20.9621 35.4451 20.7198 35.6238 20.5412C35.8024 20.3626 36.0447 20.2622 36.2973 20.2622C36.5499 20.2622 36.7922 20.3626 36.9708 20.5412C37.1495 20.7198 37.2498 20.9621 37.2498 21.2147Z"
                          stroke="#595C97"
                          stroke-width="3.81011"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <img
                    :src="`${baseURL}${registerForm.selfiePicture}`"
                    class="w-100 h-100"
                    style="object-fit: cover;"
                    alt=""
                    v-else
                  />
                </div>
              </a-form-item>
            </a-form-item>
          </div>
        </div>
        <a-form-item name="gender" label="Jenis Kelamin" :class="$style['custom-form-item']">
          <a-radio-group v-model:value="registerForm.gender" class="w-100 row" button-style="solid">
            <a-radio-button class="w-100 col-6" value="male">Pria</a-radio-button>
            <a-radio-button class="w-100 col-6" value="female">Wanita</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item name="email" label="Email" :class="$style['custom-form-item']">
          <a-input
            v-model:value="registerForm.email"
            placeholder="Masukkan Email anda"
            :class="$style['custom-input-register']"
          />
        </a-form-item>
        <a-form-item
          name="city"
          label="City"
          :class="$style['custom-form-item']"
          class="border-none"
        >
          <a-select
            v-model:value="registerForm.city"
            show-search
            placeholder="Pilih atau cari kota"
            :class="$style['custom-input-register']"
            :filter-option="false"
            @search="onSearchCity"
          >
            <a-select-option value="" disabled selected>
              Pilih atau cari kota
            </a-select-option>
            <a-select-option v-for="item in filteredCities" :key="item.id" :value="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item name="password" label="Kata Sandi" :class="$style['custom-form-item']">
          <a-input-password
            v-model:value="registerForm.password"
            placeholder="Masukkan sandi anda"
            :class="$style['custom-input-password-register']"
          />
        </a-form-item>

        <div style="border-bottom: 1px solid #800152; text-align: center" class="my-5">
          <h1 :class="$style['custom-title-register']">KONTAK PERWAKILAN</h1>
        </div>
        <a-form-item
          name="nama perwakilan"
          label="Nama Perwakilan"
          :class="$style['custom-form-item']"
        >
          <a-input
            v-model:value="registerForm.representativeName"
            placeholder="Masukkan Nama Perwakilan anda"
            :class="$style['custom-input-register']"
          />
        </a-form-item>
        <a-form-item name="telepon" label="Telepon Perwakilan" :class="$style['custom-form-item']">
          <a-input
            v-model:value="registerForm.representativePhone"
            placeholder="Masukkan Telepon Perwakilan"
            :class="$style['custom-input-register']"
          />
        </a-form-item>
        <a-form-item name="hubungan" label="Hubungan" :class="$style['custom-form-item']">
          <a-input
            v-model:value="registerForm.representativeRelationship"
            placeholder="Masukkan Hubungan anda"
            :class="$style['custom-input-register']"
          />
        </a-form-item>
        <a-modal
          v-model:visible="modalStatus.ktp"
          @cancel="handleCancel"
          @ok="handleOk"
          title="Unggah Foto KTP"
        >
          <div class="row">
            <div class="col-7">
              <a-upload
                :custom-request="handleUploadKTP"
                list-type="picture-card"
                v-model:file-list="fileListKTP"
                :disabled="openedCam"
                @preview="handlePreviewKtp"
              >
                <div v-if="!fileListKTP.length">
                  <i class="fa fa-plus" />
                  <div class="ant-upload-text">Upload KTP</div>
                </div>
              </a-upload>
            </div>
            <a-button class="col-5" @click="openCam" :disabled="!!fileListKTP.length"
              >Camera</a-button
            >
          </div>
          <div v-if="openedCam">
            <camera
              :resolution="{ width: 300, height: 200 }"
              ref="camera"
              :autoplay="true"
            ></camera>
            <a-button @click="snapshotKtp">Capture</a-button>
          </div>
        </a-modal>
        <a-modal
          v-model:visible="modalStatus.selfie"
          @cancel="handleCancel"
          @ok="handleOk"
          title="Unggah Foto Selfie"
        >
          <div class="row">
            <div class="col-7">
              <a-upload
                :custom-request="handleUploadSelfie"
                list-type="picture-card"
                v-model:file-list="fileListSelfie"
                :disabled="openedCam"
                @preview="handlePreviewSelfie"
              >
                <div v-if="!fileListSelfie.length">
                  <i class="fa fa-plus" />
                  <div class="ant-upload-text">Upload Selfie</div>
                </div>
              </a-upload>
            </div>
            <a-button class="col-5" @click="openCam" :disabled="!!fileListSelfie.length"
              >Camera</a-button
            >
          </div>
          <div v-if="openedCam">
            <camera
              :resolution="{ width: 300, height: 200 }"
              ref="camera"
              :autoplay="true"
            ></camera>
            <a-button @click="snapshotSelfie">Capture</a-button>
          </div>
        </a-modal>

        <a-button
          type="primary"
          html-type="submit"
          :class="$style['custom-button-register']"
          :loading="loading"
        >
          <strong>Kirim</strong>
        </a-button>
        <!-- <a-button type="primary" class="text-center w-100" :loading="loading" html-type="submit">
          <strong>Sign Up</strong>
        </a-button> -->
      </a-form>
      <!-- <div>
        <span class="mr-1">By signing up, you agree to the</span>
        <a href="javascript: void(0);" class="vb__utils__link">Terms of Service</a>
        and
        <a href="javascript: void(0);" class="vb__utils__link">Privacy Policy</a>
      </div> -->
    </div>
    <!-- <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Already have an account?</span>
      <router-link to="/auth/login" class="vb__utils__link">
        Sign in
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { flatMap } from 'lodash'
import { computed, reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import apiClient, { baseURL } from '../../../services/axios'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'VbRegister',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const rules = {
      fullname: [
        {
          required: true,
          message: 'Please input your name!',
          trigger: 'change',
        },
      ],
      dateOfBirth: [
        {
          validator: (_, value) => {
            if (!value || isNaN(new Date(value).getTime())) {
              return Promise.reject(new Error('Invalid Date Format!'))
            }
            return Promise.resolve()
          },
          trigger: 'change',
        },
      ],
      gender: [
        {
          required: true,
          message: 'Please input your gender!',
          trigger: 'change',
        },
      ],
      idPicture: [
        {
          required: true,
          message: 'Please input your Id Picture!',
          trigger: 'change',
        },
      ],
      selfiePicture: [
        {
          required: true,
          message: 'Please input your Selfie Picture!',
          trigger: 'change',
        },
      ],
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
      city: [
        {
          required: true,
          message: 'Please input your city!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
      idNumber: [
        {
          required: true,
          message: 'Please input your KTP number!',
          trigger: 'change',
        },
        {
          validator: (_, value) => {
            if (!/^\d{16}$/.test(value)) {
              return Promise.reject(new Error('KTP number must be 16 digits!'))
            }
            return Promise.resolve()
          },
          trigger: 'change',
        },
      ],
    }
    const registerForm = reactive({
      fullname: '',
      dateOfBirth: '',
      idNumber: '',
      idPicture: '',
      selfiePicture: '',
      gender: '',
      email: '',
      emailApproved: '',
      password: '',
      representativeName: '',
      representativePhone: '',
      representativeRelationship: '',
      city: '',
    })
    const fileListKTP = ref([])
    const fileListSelfie = ref([])
    const previewVisibleKtp = ref(false)
    const previewImageKtp = ref('')
    const handleCancelKtp = () => {
      previewVisibleKtp.value = false
    }
    const handlePreviewKtp = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImageKtp.value = file.url || file.preview
      previewVisibleKtp.value = true
    }
    const previewVisibleSelfie = ref(false)
    const previewImageSelfie = ref('')
    const handleCancelSelfie = () => {
      previewVisibleSelfie.value = false
    }
    const handlePreviewSelfie = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImageKtp.value = file.url || file.preview
      previewVisibleKtp.value = true
    }
    const modalStatus = ref({
      ktp: false,
      selfie: false,
    })
    const openedCam = ref(false)
    const handleFinish = () => {
      store.dispatch('user/REGISTER', { payload: registerForm })
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }
    const handleClickKtpModal = () => {
      modalStatus.value.ktp = true
    }
    const handleClickSelfieModal = () => {
      modalStatus.value.selfie = true
    }
    const handleUploadKTP = async ({ file, onSuccess, onError, onProgress }) => {
      try {
        const newform = new FormData()
        newform.append('upload', file)
        const result = await apiClient.post('/upload/id', newform, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        // console.log(result, 'iniuploadktpresult')
        registerForm.idPicture = result.data.data
        onSuccess(result.data)
      } catch (error) {
        console.log(error, '<<<')
        onError(error)
      }
    }
    const handleUploadSelfie = async ({ file, onSuccess, onError, onProgress }) => {
      try {
        const newform = new FormData()
        newform.append('upload', file)
        const result = await apiClient.post('/upload/selfie', newform, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        registerForm.selfiePicture = result.data.data
        onSuccess(result.data)
      } catch (error) {
        console.log(error, '<<<')
        onError(error)
      }
    }
    const cities = ref([])
    const filteredCities = ref([])
    onMounted(() => {
      getCity()
    })

    const getCity = async () => {
      const { data } = await apiClient.get('/city')
      cities.value = data
      filteredCities.value = data
      console.log(data, 'iniresultity')
    }

    const camera = ref(null)

    const openCam = () => {
      openedCam.value = !openedCam.value
    }
    const snapshotKtp = async () => {
      const blob = await camera.value?.snapshot()

      // To show the screenshot with an image tag, create a url
      // const url = URL.createObjectURL(blob)

      const newform = new FormData()
      newform.append('upload', blob, 'id-picture.png')
      const result = await apiClient.post('/upload/id', newform, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      registerForm.idPicture = result.data.data
      // console.log(url, '<<<<URL')
      if (result.data.data) {
        handleOk()
      }
    }
    const snapshotSelfie = async () => {
      const blob = await camera.value?.snapshot()

      // To show the screenshot with an image tag, create a url
      // const url = URL.createObjectURL(blob)

      const newform = new FormData()
      newform.append('upload', blob, 'id-picture.png')
      const result = await apiClient.post('/upload/selfie', newform, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      registerForm.selfiePicture = result.data.data
      // console.log(url, '<<<<URL')
      if (result.data.data) {
        handleOk()
      }
    }
    const handleCancel = async () => {
      await camera.value?.stop()
      modalStatus.value.ktp = false
      modalStatus.value.selfie = false
      openedCam.value = false
    }
    const handleOk = async () => {
      await camera.value?.stop()
      modalStatus.value.ktp = false
      modalStatus.value.selfie = false
      openedCam.value = false
    }
    const onSearchCity = value => {
      // Filter daftar kota berdasarkan input pengguna
      filteredCities.value = cities.value.filter(city =>
        city.name.toLowerCase().includes(value.toLowerCase()),
      )
    }
    return {
      handleCancelKtp,
      handlePreviewKtp,
      previewImageKtp,
      previewVisibleKtp,
      handleCancelSelfie,
      handlePreviewSelfie,
      previewImageSelfie,
      previewVisibleSelfie,

      filteredCities,
      onSearchCity,
      settings,
      loading,
      rules,
      registerForm,
      modalStatus,
      fileListKTP,
      fileListSelfie,
      camera,
      openedCam,
      baseURL,
      handleFinish,
      handleFinishFailed,
      handleClickKtpModal,
      handleClickSelfieModal,
      handleUploadKTP,
      handleUploadSelfie,
      snapshotKtp,
      snapshotSelfie,
      openCam,
      handleCancel,
      handleOk,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
:global(.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  background: linear-gradient(to right, #1d2671, #830051) !important;
  border: 1px solid #1d2671 !important;
}
:global(.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
  background-color: white !important;
}
:global(.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover) {
  background: linear-gradient(to right, #1d2671, #830051) !important;
  background-color: linear-gradient(to right, #1d2671, #830051) !important;

  // border: none !important;
}
:global(.ant-select-selector) {
  border: none !important;
}
:global(.ant-select-selector .ant-select-selector:focus-visible) {
  border: none !important;
}
.custom-input-register {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;
  &:focus {
    border-bottom: 1px solid #800152 !important;
  }
  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}
.custom-input-password-register {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }
  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}
.custom-input-date-register {
  // border: none !important;
  // border-bottom: 1px solid #e4e9f0 !important;
  // border-radius: 0 !important;
  input {
    border: none !important;
    border-bottom: 1px solid #e4e9f0 !important;
    border-radius: 0 !important;
    &:focus {
      border-bottom: 1px solid #800152 !important;
    }
    &:hover {
      border-bottom: 1px solid #800152 !important;
    }
  }
}
.custom-form-item {
  label::before {
    content: '' !important;
  }
}
.custom-form-item-upload {
  label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  label::before {
    content: '' !important;
  }
}
.custom-container-upload-file {
  background: #f2f2f2;
  border: 1.5px dashed #b4b4b4;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  max-height: 200px;
  cursor: pointer;
  // padding: 0 28px;
}
.custom-title-register {
  font-size: 32px;
  font-weight: 500;
  color: #800152;
}
.custom-button-register {
  width: 100%;
  height: 68px !important;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}
</style>
